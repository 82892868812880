import { useEffect, useRef, useState } from 'react';

export const useDismissiblePopover = (dismissTimer?: number) => {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [dismissPopover, setDismissPopover] = useState(false);

  const dismissOnBlur = (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement;

    if (popoverRef?.current && !popoverRef.current.contains(targetElement)) {
      setDismissPopover(true);
    }
  };

  useEffect(() => {
    let dismissPopoverTimer: NodeJS.Timeout;

    if (dismissTimer) {
      dismissPopoverTimer = setTimeout(() => {
        setDismissPopover(true);
      }, dismissTimer);
    }

    document.addEventListener('click', dismissOnBlur);

    return () => {
      dismissPopoverTimer && clearTimeout(dismissPopoverTimer);
      document.removeEventListener('click', dismissOnBlur);
    };
  }, []);

  return { popoverRef, dismissPopover };
};
